@import "../../../../styles/vars";
@import "../../../../styles/mixins";


.navbarServicesDropdown {
  position: absolute;
  border-top: 1px solid #FFFFFF;
  background: $color-black;
  width: 100%;
  top: 24px;
  padding: 14px;

  @include lgScreenMedia {
    display: none;
  }

  &__container {
    @include flexCol(space-between);
    max-width: 1206px;
    width: 100%;
    margin: 0 auto;

    @include xxlScreenMedia {
      max-width: 955px;
    }

    @include xlScreenMedia {
      max-width: 767px;
    }
  }

  &__menu {
    @include flexCol(flex-start,flex-start);
    flex-direction: column;
    gap: 30px;

    @include xxlScreenMedia {
      gap: 28px;
    }

    @include xxlScreenMedia {
      gap: 22px;
    }
  }

  &__link {
    @include setFont(28px,500);
    color: $color-white;
    text-decoration: none;
    transition: $transition;

    @include xxlScreenMedia {
      font-size: 24px;
    }

    &:hover,&_active {
      color: $color-light-blue;
      text-decoration: underline;
    }

  }

  &__imageBlock {
    width: 50%;
    overflow: hidden;
    position: relative;
  }

  &__img {
    width: 100%;
    transition: $transition;

    &:not(:first-child) {
      position: absolute;
      left: 0;
      top: 0;
    }
  }


}
