@import "../../../styles/vars";
@import "../../../styles/mixins";


.contactHeader {
  @include flexCol();
  padding-top: 95px;
  padding-bottom: 48px;

  @include xlScreenMedia {
    padding-top: 89px;
  }

  @include lgScreenMedia {
    padding-top: 80px;
  }

  @include mdScreenMedia {
    flex-direction: column;
    align-items: flex-start;
  }

  @include smScreenMedia {
    padding-top: 69px;
    padding-bottom: 22px;
  }


  &__textBlock {
    max-width: 720px;
    position: relative;

    @include mdScreenMedia {
      max-width: 450px;
    }
  }

  &__title {
    color: $color-white;
    margin-bottom: 60px;

    @include xxlScreenMedia {
      margin-bottom: 55px;
    }

    @include xlScreenMedia {
      margin-bottom: 50px;
    }

    @include lgScreenMedia {
      margin-bottom: 40px;
      font-size: 36px!important;
    }

    @include smScreenMedia {
      font-size: 32px!important;
    }
  }

  &__link {
    @include setFont(28px,400);
    color: $color-white;
    text-decoration: none;
    transition: $transition;
    display: block;

    @include xlScreenMedia {
      font-size: 24px;
    }

    @include lgScreenMedia {
      font-size: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;

      @include mdScreenMedia {
        margin-bottom: 25px;
      }

      @include smScreenMedia {
        margin-bottom: 20px;
        font-size: 15px;
      }
    }

    &:last-child {
      margin-bottom: 4px;

      @include smScreenMedia {
        margin-bottom: 0;
      }
    }

    &:hover {
      color: $color-light-blue;
    }
  }

  &__socIconsBlock {
    @include setDistance(margin-top,60);
    @include flexCol(flex-start);
    gap: 20px;
  }

  &__icon {
    @include svg(43px,$color-white);

    @include lgScreenMedia {
      width: 30px;
    }

    @include smScreenMedia {
      width: 21px;
    }

    &:hover {
      fill: $color-light-blue;
    }
  }

  &__imgBlock {
    width: 100%;
    margin-left: -130px;

    @include mdScreenMedia {
      margin-left: 0;
    }
  }

  &__img {
    width: 100%;
  }

}
