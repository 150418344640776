.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 216, 255, 0.2) 0%, rgba(0, 216, 255, 0.1) 100%);
  backdrop-filter: blur(2px);

  &_high {
    z-index: 9999;
  }

  &_low {
    z-index: 888;
  }
}