@import "../../../styles/vars";
@import "../../../styles/mixins";

.indBlogUserBlock {
  @include flexCol();
  gap: 30px;

  @include mdScreenMedia {
    gap: 20px;
  }

  &__img {
    width: 84px;
    border-radius: 50%;

    @include mdScreenMedia {
      width: 58px;
    }
  }

  &__name {
    @include setFont(20px, 500);
    color: $color-white;
    margin-bottom: 20px;

    @include mdScreenMedia {
      margin-bottom: 10px;
    }

    @include xxlScreenMedia {
      font-size: 18px;
    }

    @include xlScreenMedia {
      font-size: 16px
    }

    @include smScreenMedia {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

