@import "vars";
@import "mixins";


*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:not(i) {
  font-family: $font-fam-def;

}

body {
  background: $color-black;
}

.container {
  max-width: 1728px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 14px;
  padding-left: 14px;

  @include xxlScreenMedia {
    max-width: 1339px;
  }

  @include xlScreenMedia {
    max-width: 1148px;
  }

  @include lgScreenMedia {
    max-width: 944px
  }
}

.containerSmall {
  max-width: 1800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 60px;
  padding-left: 60px;

  @include xxlScreenMedia {
    max-width: 1362px;
    padding-right: 50px;
    padding-left: 50px;
  }

  @include xlScreenMedia {
    max-width: 1160px;
    padding-right: 40px;
    padding-left: 40px;
  }

  @include lgScreenMedia {
    max-width: 959px;
    padding-right: 20px;
    padding-left: 20px;
  }

  @include mdScreenMedia {
    padding-left: 14px;
    padding-right: 14px;
  }
}

.contentTextDef {
  @include setFont(20px, 300);
  color: $color-white;
  line-height: 25px;

  @include xxlScreenMedia {
    font-size: 18px;
  }

  @include xlScreenMedia {
    line-height: 22px;
    font-size: 16px
  }

  @include smScreenMedia {
    font-size: 14px;
    line-height: 21px;
  }
}

.lightBlueText {
  color: $color-light-blue;
}

.titleDef {
  @include setFont(42px, 500);
  color: $color-white;

  @include xxlScreenMedia {
    font-size: 36px;
  }

  @include xlScreenMedia {
    font-size: 30px;
  }

  @include lgScreenMedia {
    font-size: 25px;
  }

  @include smScreenMedia {
    font-size: 22px;
  }
}

.sliderDotesDef {
  display: block;

  .slick-dots {
    bottom: -30px;

    @include smScreenMedia {
      bottom: -25px;
    }
  }

  .slick-dots li {
    width: auto;
    height: auto;
    margin: 0 6px;
  }

  .slick-dots .slick-active div {
    background: $color-light-blue;
  }
}

.headerTitle {
  @include setFont(62px, 500);
  line-height: 71px;

  @include xxlScreenMedia {
    font-size: 56px;
    line-height: 64px;
  }

  @include xlScreenMedia {
    font-size: 46px;
    line-height: 52px;
  }

  @include smScreenMedia {
    line-height: 37px;
    font-size: 32px;
  }
}

.inputDef {
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 2px solid $color-white;
  padding: 0 2px 8px;
  color: $color-white;
  transition: $transition;

  @include setFont(20px, 300);

  @include xxlScreenMedia {
    font-size: 18px;
  }

  @include xlScreenMedia {
    font-size: 16px
  }

  @include smScreenMedia {
    font-size: 14px;
    border-width: 1px;
  }

  &:not(&_disabled) {
    &:hover, &:focus, &:focus-within {
      border-color: $color-light-blue;
    }
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $color-white;
    box-shadow: 0 0 0px 1000px $color-black inset;
  }

  ::placeholder {
    color: #FFFFFFCC;
  }
}

.textAreaDef {
  scroll-padding: 12px;
  resize: none;
  height: 62px;
  grid-column: 1/ span 2;
  resize: none;
  border: none !important;
  padding: 0 !important;
}

.scrollbarDef {
  cursor: auto;

  &::-webkit-scrollbar {
    cursor: default;
    background: $color-white;
    border-radius: 6px;
    margin-top: 16px;
    margin-right: 9px;
    background-clip: padding-box;
    width: 10px;
    border-bottom: 12px solid #FFFFFF;
  }

  &::-webkit-scrollbar-thumb {
    cursor: default !important;
    width: 14px;
    border-radius: 6px;
    background-clip: padding-box;
    background: $color-light-blue;
    background-image: url(../assets/images/global/scrollbaritem.png);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.lightBlueBorderBox {
  width: calc(100% - 64px);
  border-radius: 30px;
  box-shadow: 0 0 0 32px rgba($color-light-blue, .2);
  overflow: hidden;
  margin: 32px;

  @include xlScreenMedia {
    box-shadow: 0 0 0 20px rgba($color-light-blue, .2);
    margin: 20px;
    width: calc(100% - 40px);
    border-radius: 24px;
  }

  @include lgScreenMedia {
    border-radius: 20px;
  }

  @include mdScreenMedia {
    box-shadow: 0 0 0 14px rgba($color-light-blue, .2);
    margin: 14px;
    width: calc(100% - 28px);
    border-radius: 18px
  }

  @include smScreenMedia {
    box-shadow: 0 0 0 4px rgba($color-light-blue, .2);
    margin: 4px;
    width: calc(100% - 8px);
    border-radius: 8px;
  }
}


//Cookie start
#cookie-notification {
  background-image: url("../../src/assets/images/cookie/cookie.webp");
  width: 615px;
  height: 321px;
  border-radius: 16px;
}

.notification-buttons {
  position: absolute;
  right: 44px;
  bottom: 80px;
}

.notification-desc {
  position: absolute;
  right: 34px;
  top: 90px;
  font-size: 20px;
  line-height: 23px;
  max-width: 280px;
}

#cookie-notification .notification-button.button-accept:hover {
  border: 1px solid white;
}

#cookie-notification .cookie-notification__decline:hover {
  border: 1px solid #00D8FF !important;
  color: white !important;
  //background-color: transparent!important;
}

#cookie-notification__decline {
  width: 124px !important;
  height: 50px !important;
  border-radius: 4px;
  background: #fff;
  color: #00D8FF !important;
  font-size: 16px;
  margin-left: 290px;
  font-weight: 500;
}

#cookie-notification__accept {
  width: 124px !important;
  height: 50px !important;
  border-radius: 4px;
  background: #00D8FF !important;
  color: #fff !important;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 500;
}

#cookie-notification__accept:hover {
  border: 1px solid #fff !important;
}

#cookie-notification__decline:hover {
  border: 1px solid #00D8FF !important;
  color: white !important;
}

.cookieButtonWrap {
  flex-direction: row !important;
  align-items: flex-start !important;
}


@media only screen and (max-width: 768px) {
  #cookie-notification {
    background-repeat: round;
    background-size: 332px;
    height: 169px;
    border-radius: 12px;
    width: 390px;
  }

  .notification-buttons {
    bottom: 20px;
  }

  .notification-desc {
    font-size: 12px;
    line-height: 14px;
    top: 50px;
    width: 175px;
  }

  #cookie-notification__accept, #cookie-notification__decline {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    height: 32px !important;
    width: 77px !important;
    padding: 0;
  }

  #cookie-notification__decline {
    background: #fff;
    color: #00d8ff;
    margin-left: 170px;
  }
}

@media only screen and (max-width: 480px) {
  #cookie-notification__accept {
    margin-left: 15px !important;
  }

  #cookie-notification__decline {
    margin-left: 128px !important;
  }

  .notification-buttons {
    right: 24px;
    bottom: 30px;
  }

  .notification-desc {
    top: 40px;
    right: 15px;
  }
}

//cookie end
