@import "../../../styles/vars";
@import "../../../styles/mixins";

.secondaryBtn {
  cursor: pointer;
  border: 1px solid $color-light-blue;
  background: transparent;
  color: $color-light-blue;
  min-width: 148px;
  white-space: nowrap;
  text-align: center;
  padding: 22px 10px;
  border-radius: 4px;
  transition: $transition;
  @include setFont(20px,500);

  @include  xxlScreenMedia {
    min-width: 136px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 18px;
  }

  @include  xlScreenMedia {
    min-width: 124px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 16px;
  }

  @include lgScreenMedia {
    font-size: 16px;
    min-width: 208px;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  @include smScreenMedia {
    min-width: 0;
    width: 100%;
    max-width: 293px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &:hover,
  &:active {
    background-color: $color-light-blue;
    color: $color-white;
  }
}