@import "../../../styles/vars";
@import "../../../styles/mixins";

.uiUxDesignHeader {
  background: $color-white;
  padding-top: 185px;
  margin-bottom: -300px;

  @include xxlScreenMedia {
    padding-top: 160px;
  }

  @include xlScreenMedia {
    margin-bottom: -270px;
    padding-top: 140px;
  }

  @include lgScreenMedia {
    margin-bottom: -220px;
    padding-top: 120px;
  }

  @include mdScreenMedia {
    margin-bottom: -150px;
  }

  @include smScreenMedia {
    margin-bottom: -60px;
    padding-top: 78px;
  }
  


  &__title {
    text-align: center;
    color: $color-black;
    @include setDistance(margin-bottom,60);

    @include smScreenMedia {
      text-align: left;
    }
  }

  &__imgBlock {
    z-index: 1;
    position: relative;

  }

  &__picture {
    width: 100%;
  }

  &__img {
    width: 100%;
    transition: 7s transform linear;
  }


}
