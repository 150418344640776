@import "../../../styles/vars";
@import "../../../styles/mixins";

.appDevelopmentHeader {
  position: relative;
  width: 100%;
  margin-bottom: -255px;
  min-height: 700px;

  @include lgScreenMedia {
    margin-bottom: -150px;
  }

  @include mdScreenMedia {
    min-height: 500px;
  }

  &__img {
    width: 100%;
    z-index: -1;
    position: relative;

    &_blur {
      filter: blur(5px);
    }
  }

  &__textBlock {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    @include flexCol(flex-start);

    @include lgScreenMedia {
      margin-top: 128px;
    }

    @include mdScreenMedia {
      align-items: flex-start;
      margin-top: 90px;
    }

    @include smScreenMedia {
      margin-top: 68px;
    }
  }

  &__textBlockContainer {
    transform: translateY(-50%);

    @include mdScreenMedia {
      transform: none;
    }
  }

  &__title {
    max-width: 700px;
    color: $color-white;
    margin-bottom: 40px;

    @include xlScreenMedia {
      max-width: 600px;
    }

    @include lgScreenMedia {
      max-width: 450px;
      font-size: 36px;
    }

    @include mdScreenMedia {
      max-width: 550px;
      margin-bottom: 20px;
    }

    @include smScreenMedia {
      max-width: 380px;
    }
  }

  &__contentText {
    max-width: 600px;
    margin-bottom: 15px;
    @include xlScreenMedia {
      max-width: 400px;
    }

    @include lgScreenMedia {
      max-width: 300px;
    }
  }
}