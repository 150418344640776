// medias

$xxsm-screen: 320px;
$xsm-screen: 450px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 1024px;
$xl-screen: 1280px;
$xxl-screen: 1512px;

// colors
$color-black: #111111;
$color-light-blue: #00D8FF;
$color-white: #FFFFFF;
$color-light-red: #F93434;
$color-dark-red: #F93434CC;
$color-green: #68FF8C;

$font-fam-def: 'Ubuntu', sans-serif;
$transition: all .5s