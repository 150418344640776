@import "../../../styles/vars";
@import "../../../styles/mixins";

.webDevelopmentHeader {
  width: 100%;
  overflow: hidden;
  @include setDistance(padding-bottom, 140);

  &__container {
    padding-top: 185px;
    padding-bottom: 100px;

    @include xxlScreenMedia {
      padding-top: 160px;
    }

    @include xlScreenMedia {
      padding-top: 140px;
    }

    @include lgScreenMedia {
      padding-top: 120px;
      padding-bottom: 60px;
    }

    @include mdScreenMedia {
      padding-bottom: 40px;
    }

    @include smScreenMedia {
      padding-top: 78px;
    }
  }

  &__title {
    @include setDistance(margin-bottom, 60);
    color: $color-white;
    text-align: center;
  }

  &__main {
    @include flexCol();
    gap: 34px;
  }

  &__mainImgBlock {
    width: 100%;
    position: relative;
  }

  &__mainImg {
    width: 100%;
  }

  &__sensorImgBlock {
    position: absolute;
    left: 60px;
    bottom: -50px;
    background-image: url("../../../assets/images/WebDev/webDevelopmentHeader/webDevSensorImg.png");
    background-size: contain;
    background-position: center;
    width: 228px;
    height: 228px;
    @include flexCol();
    flex-direction: column;

    @include xxlScreenMedia {
      width: 190px;
      height: 190px;
    }

    @include xlScreenMedia {
      bottom: -55px;
    }

    @include lgScreenMedia {
      width: 150px;
      height: 150px;
      left: 30px;
    }

    @include lgScreenMedia {
      bottom: -30px;
    }

    @media (max-width: 950px) {
      bottom: -20px;
      width: 120px;
      height: 120px;
    }

    @media (max-width: 850px) {
      width: 110px;
      height: 110px;
    }
    @media (max-width: 720px) {
      width: 95px;
      height: 95px;
      left: 20px;
    }

    @media (max-width: 460px){
      width: 65px;
      height: 65px;
      left: 10px;
    }
  }

  &__mbpsText {
    @include setFont(20px, 400);
    color: $color-white;
    text-align: center;
    position: absolute;
    top: 65%;
    transform: translateY(-50%);

    @include xxlScreenMedia {
      font-size: 18px;
    }

    @include xlScreenMedia {
      font-size: 16px
    }

    @include lgScreenMedia {
      font-size: 14px;
    }

    @media (max-width: 950px) {
      font-size: 10px;
    }

    @include smScreenMedia {
      font-size: 6px;
    }

    &::after {
      content: '60 MBPS';
      animation: timer 5s ease-out 1 forwards;
    }
  }

  &__sensorCenterImg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include lgScreenMedia {
      width: 15px;
    }

    @media (max-width: 720px) {
      width: 10px;
    }

    @include smScreenMedia {
      width: 7px;
    }
  }

  &__sensorArrow {
    width: 100%;
    display: flex;
  }

  &__sensorArrowImg {
    transform-origin: right;
    animation: sensor 5s ease-out 1 forwards;
    width: 50%;
  }

  &__progressBlock {
    position: absolute;
    right: 13px;
    bottom: -50px;
    max-width: 540px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid $color-light-blue;
    padding: 12px 10px;
    @include flexCol(flex-start);
    gap: 32px;
    background: #1C1C1C;

    @include xxlScreenMedia {
      max-width: 460px;
    }

    @include xlScreenMedia {
      max-width: 390px;
      bottom: -55px;
    }

    @include lgScreenMedia {
      max-width: 320px;
      gap: 20px;
      padding: 8px;
      bottom: -30px;
    }
    @media (max-width: 950px) {
      max-width: 260px;
      gap: 12px;
      bottom: -20px;
    }

    @media (max-width: 850px) {
      max-width: 223px;
      padding: 6px;
    }

    @media (max-width: 720px) {
      right: 8px;
    }
    @media (max-width: 650px) {
      max-width: 190px;
    }

    @media (max-width: 500px) {
      max-width: 160px;
      gap: 6px;
    }

    @media (max-width: 446px) {
      right: 4px;
    }

    @media (max-width: 460px) {
      padding: 3px;
      max-width: 120px;
      border-radius: 5px;
      gap: 3px;
    }
  }

  &__laptopImg {
    max-width: 102px;
    width: 100%;

    @include xlScreenMedia {
      max-width: 75px;
    }

    @include lgScreenMedia {
      max-width: 65px;
    }

    @media (max-width: 950px) {
      max-width: 54px;
    }

    @media (max-width: 850px) {
      max-width: 40px;
    }

    @media (max-width: 650px) {
      max-width: 30px;
    }

    @media (max-width: 460px) {
      max-width: 25px;
    }
  }

  &__progressBars {
    max-width: 60.5%;
    width: 100%;
  }

  &__progressbarBlock {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;

      @include lgScreenMedia {
        margin-bottom: 10px;
      }

      @media (max-width: 950px) {
        margin-bottom: 6px;
      }

      @media (max-width: 850px) {
        margin-bottom: 4px;
      }

      @media (max-width: 460px) {
        margin-bottom: 2px;
      }
    }
  }

  &__progressbarLabel {
    @include flexCol(flex-start);
    gap: 10px;
    @include setFont(14px, 700);
    color: rgba($color-white, .7);
    margin-bottom: 4px;

    @media (max-width: 950px) {
      font-size: 12px;
    }

    @media (max-width: 850px) {
      gap: 6px;
      margin-bottom: 2px;
    }

    @media (max-width: 650px) {
      font-size: 8px;
    }

    @media (max-width: 460px) {
      font-size: 6px;
    }
  }

  &__progressbarLabelImg {
    width: 20px;

    @media (max-width: 950px) {
      width: 16px;
    }

    @media (max-width: 850px) {
      width: 14px;
    }

    @media (max-width: 500px) {
      width: 10px;
    }

    @media (max-width: 460px) {
      width: 6px;
    }
  }

  &__progressbar {
    height: 5px;
    width: 100%;
    border-radius: 10px;
    background: #10BBCC;

    @media (max-width: 950px) {
      height: 3px;
    }

    @media (max-width: 460px) {
      height: 1px;
    }
  }

  &__progressbarInner {
    width: 50%;
    height: 100%;
    border-radius: 10px;
    background: #5AF598;

    &_progressUp {
      animation: progressUp 5s ease-out 1 forwards;
    }

    &_progressDown {
      animation: progressDown 5s ease-out 1 forwards;
    }
  }


  &__cpuBlock {
    width: 448px;
    margin-right: -120px;
    margin-left: -160px;
    margin-bottom: -240px;
    padding-right: 14px;
    
    @media (max-width: 1770px) {
      margin-bottom: -228px;
    }

    @media (max-width: 1750px) {
      margin-bottom: -220px;
    }

    @media (max-width: 1700px) {
      margin-bottom: -200px;
    }

    @media (max-width: 1650px) {
      margin-bottom: -180px;
    }

    @media (max-width: 1620px) {
      margin-bottom: -165px;
    }

    @media (max-width: 1570px) {
      margin-bottom: -145px;
    }

    @include xxlScreenMedia {
      width: 420px;
      margin-left: -150px;
      margin-right: -110px;
      margin-bottom: -100px;
    }

    @include xlScreenMedia {
      width: 345px;
      margin-left: -130px;
      margin-right: -95px;
    }
    @include lgScreenMedia {
      width: 300px;
      margin-left: -115px;
      margin-right: -80px;
      margin-bottom: -60px;
    }

    @media (max-width: 950px) {
      width: 260px;
      margin-left: -98px;
      margin-right: -73px;
    }
    @media (max-width: 850px) {
      width: 230px;
      margin-left: -90px;
      margin-right: -65px;
      margin-bottom: -85px;
    }

    @media (max-width: 820px) {
      margin-bottom: -70px;
    }
    @media (max-width: 755px) {
      margin-bottom: -52px;
    }

    @media (max-width: 720px) {
      width: 206px;
      margin-right: -60px;
    }

    @media (max-width: 650px) {
      width: 192px;
      margin-right: -57px;
      margin-left: -80px;
    }

    @media (max-width: 620px) {
      width: 180px;
      margin-right: -54px;
    }

    @include smScreenMedia {
      width: 170px;
      margin-right: -51px;
    }

    @media (max-width: 545px) {
      width: 155px;
      margin-right: -47px;
      margin-left: -75px;
    }

    @media (max-width: 500px) {
      width: 147px;
      margin-right: -45px;
      margin-left: -70px;
    }

    @media (max-width: 470px) {
      width: 138px;
    }
    @media (max-width: 446px) {
      width: 131px;
      margin-right: -42px;
      margin-left: -65px;
    }

    @media (max-width: 425px) {
      width: 125px;
    }

    @media (max-width: 400px) {
      width: 120px;
      margin-right: -39px;
    }

    @media (max-width: 385px) {
      width: 113px;
      margin-left: -63px;
    }

    @media (max-width: 460px) {
      width: 107px;
      margin-left: -61px;
      margin-right: -36px;
    }

    @media (max-width: 350px) {
      width: 102px;
    }

    @media (max-width: 330px) {
      width: 97px;
      margin-left: -57px;
      margin-right: -34px;
    }
  }

  &__cpuImg {
    width: 100%;
  }
}


@keyframes sensor {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes progressUp {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes progressDown {
  from {
    width: 100%;
  }
  to {
    width: 20%;
  }
}

@keyframes timer{
  @for $i from 0 through 100 {
    $percentage : percentage($i / 100);
    #{$percentage} {
      content: '#{300 * $i / 100} MBPS';
    }
  }
}