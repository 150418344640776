@import "../../../styles/vars";
@import "../../../styles/mixins";

.blogHeader {
  padding-top: 213px;
  padding-bottom: 153px;
  @include flexCol();
  gap: 187px;

  @include xxlScreenMedia {
    gap: 130px;
    padding-top: 190px;
    padding-bottom: 130px;
  }
  @include xlScreenMedia {
    gap: 80px;
    padding-top: 120px;
    padding-bottom: 110px;
  }

  @include lgScreenMedia {
    flex-direction: column;
  }

  @include smScreenMedia {
    padding-top: 78px;
    padding-bottom: 40px;
    gap: 56px;
  }

  &__texts {
    max-width: 500px;
    width: 100%;

    @include xlScreenMedia {
      max-width: 360px;
    }

    @include lgScreenMedia {
      max-width: 728px;
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 40px;
    color: $color-white;

    @include xxlScreenMedia {
      margin-bottom: 30px;
    }

    @include lgScreenMedia {
      margin-bottom: 34px;
    }

    @include smScreenMedia {
      margin-bottom: 18px;
    }
  }

  &__imgBlock {
    width: 100%;
    border-radius: 40px;
    background: $color-white;
    padding: 0 0 24px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0);

    @include lgScreenMedia {
      border-radius: 24px;
    }

    @include smScreenMedia {
      border-radius: 16px;
      padding-bottom: 11px;
    }
  }

  &__imgBlockHeader {
    @include flexCol(space-between);
    padding: 16px 40px 42px;
    background: $color-white;
    position: relative;
    z-index: 1;

    @include smScreenMedia {
      padding: 12px 8px 19px;
    }
  }

  &__userInfo {
    @include flexCol();
    gap: 32px;

    @include smScreenMedia {
      gap: 10px;
    }
  }

  &__userImg {
    width: 84px;
    border-radius: 50%;

    @include xlScreenMedia {
      width: 50px;
    }

    @include smScreenMedia {
      width: 43px;
    }
  }

  &__username, &__date {
    @include setFont(20px, 500);

    @include xxlScreenMedia {
      font-size: 18px;
    }

    @include xlScreenMedia {
      font-size: 16px
    }

    @include smScreenMedia {
      font-size: 14px;
    }
  }


  &__img {
    width: 100%;
    padding: 0 40px;
    position: relative;
    display: inline-block;
    transition: transform 5s linear;

    @include smScreenMedia {
      padding: 0 8px;
    }
  }
}