@import "../../../styles/vars";
@import "../../../styles/mixins";

.indBlogHeader {
  position: relative;
  margin-top: 108px;

  @include xxlScreenMedia {
    margin-top: 95px;
  }

  @include xlScreenMedia {
    margin-top: 89px;
  }

  @include lgScreenMedia {
    margin-top: 79px;
  }

  @include smScreenMedia {
    margin-top: 69px;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__particle {
    width: 100%;
    z-index: -1;
    position: relative;
    height: 100%;

    canvas {
      position: static!important;
    }
  }

  &__container {
    padding-top: 110px;

    @include xxlScreenMedia {
      padding-top: 100px;
    }

    @include xlScreenMedia {
      padding-top: 80px;
    }

    @include lgScreenMedia {
      padding-top: 60px;
    }

    @include smScreenMedia {
      padding-top: 27px;
    }
  }

  &__topBlock {
    @include flexCol(space-between,flex-start);

    @include mdScreenMedia {
      justify-content: center;
    }
  }

  &__socialBlock {
    @include mdScreenMedia {
      @include flexCol();
      flex-direction: column;
    }
  }

  &__text {
    color: $color-white;
    margin-bottom: 34px;

    @include lgScreenMedia {
      margin-bottom: 20px;
    }

    @include smScreenMedia {
      margin-bottom: 10px;
    }

    @media (max-width: 500px) {
      font-size: 20px;
    }
  }

  &__socialIconsBlock {
    @include flexCol(flex-start);
    gap: 21px;

    @include lgScreenMedia {
      gap: 10px;
    }

    @include smScreenMedia {
      gap: 5px;
    }
  }

  &__socialIcon {
    @include svg(56px,$color-white);

    @include xlScreenMedia {
      width: 40px;
      height: 40px;
    }

    @include smScreenMedia {
      width: 21px;
      height: 21px;
    }
  }

  &__userBlock {
    @include mdScreenMedia {
      display: none!important;
    }
  }

  &__mainImgBlock {
    width: 100%;
    border-radius: 40px;
    margin-bottom: -242px;
    margin-top: 60px;
    overflow: hidden;
    background: $color-white;
    height: 720px;

    @include xxlScreenMedia {
      border-radius: 36px;
      height: 650px;
    }

    @include xlScreenMedia {
      border-radius: 30px;
      height: 615px;
    }

    @include lgScreenMedia {
      height: 470px;
    }

    @include mdScreenMedia {
      border-radius: 24px;
      margin-top: 30px;
      margin-bottom: -150px;
      height: 360px;
    }

    @include smScreenMedia {
      border-radius: 20px;
      margin-top: 12px;
      margin-bottom: -132px;
      height: 250px;
    }

    @media (max-width: 400px) {
      margin-bottom: -80px;
      height: 190px;
    }

    @media (max-width: 320px) {
      border-radius: 16px;
    }
  }

  &__mainImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__loader {
    height: 100%;
  }
}
