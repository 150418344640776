@import "../../../styles/vars";
@import "../../../styles/mixins";


.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px 0;
  transition: $transition;
  z-index: 9999;

  &_active {
    background: $color-black;
  }

  @include hover {
    &:hover {
      background: $color-black;
    }
    &:hover &__logoImg {
      fill: $color-white;
    }

    &:hover &__menuLink {
      color: $color-white;
    }
  }


  &_active &__logoImg {
    fill: $color-white;
  }

  &_active &__menuLink {
    color: $color-white;
  }

  &_disabled {
    z-index: 99;
  }

  &__container {
    @include flexCol(space-between);
  }


  &__logoImg {
    @include svg(178px, $color-white, 37px);

    @include xxlScreenMedia {
      width: 162px;
      height: 33px;
    }

    @include xlScreenMedia {
      width: 151px;
    }

    @include lgScreenMedia {
      width: 137px;
      height: 28px;
    }

    @include smScreenMedia {
      width: 87px;
      height: 18px;
    }

    &_white {
      fill: $color-black;
    }
  }

  &__menu {
    max-width: 1154px;
    width: 100%;
    @include flexCol(space-between);

    @include xxlScreenMedia {
      max-width: 954px;
    }

    @include xlScreenMedia {
      max-width: 751px;
    }

    @include lgScreenMedia {
      display: none;
    }
  }

  &__menuLink {
    @include setFont(20px, 500);
    color: $color-white;
    text-decoration: none;
    transition: $transition;
    cursor: pointer;

    @include xxlScreenMedia {
      font-size: 18px;
    }

    @include xlScreenMedia {
      font-size: 16px;
    }

    &:hover,
    &_active {
      color: $color-light-blue !important;
    }

    &_white {
      color: $color-black;
    }
  }

  &__menuLink:hover &__menuLinkIcon,
  &__menuLink_active &__menuLinkIcon {
    fill: $color-light-blue;
  }

  &__menuLinkText {
    margin-right: 6px;
  }

  &__menuLinkIcon {
    @include svg(17px, $color-white, 10px);

    &_active {
      transform: rotate(180deg);
    }
  }

  &__burgerBtn {
    @include flexCol();
    gap: 10px;
    flex-direction: column;
    cursor: pointer;
    border: none;
    background: transparent;

    @media (min-width: 1025px) {
      display: none;
    }

    @include smScreenMedia {
      gap: 5px;
    }
  }

  &__burgerBtnIcon {
    @include svg(38px, $color-light-blue, 26px);

    @include smScreenMedia {
      @include svg(24px, $color-light-blue, 16px)
    }
  }
}
