@import "../../../styles/vars";
@import "../../../styles/mixins";

.FAQHeader {
  position: relative;
  margin-bottom: -20%;
  min-height: 1000px;

  @include  xxlScreenMedia {
    min-height: 700px;
  }

  @include  xlScreenMedia {
    min-height: 600px;
  }

  @include  lgScreenMedia {
    min-height: 500px;
  }

  @include mdScreenMedia {
    margin-bottom: -57%;
    min-height: 700px;
  }

  @include smScreenMedia {
    min-height: 500px;
  }

  @include xsmScreenMedia {
    margin-bottom: -40%;
    min-height: 400px;
  }

  &__img {
    width: 100%;

    &_blur {
      filter: blur(5px);
    }
  }

  &__title {
    position: absolute;
    top: 22%;
    width: 100%;
    max-width: 880px;
    color: $color-white;
    @include setFont(110px, 500);
    line-height: 126px;
    margin-left: -9px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.7) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include xxlScreenMedia {
      max-width: 600px;
      font-size: 65px;
      line-height: 70px;
      margin-left: -5px;
    }

    @include xlScreenMedia {
      max-width: 500px;
      font-size: 50px;
      line-height: 60px;
    }

    @include lgScreenMedia {
      max-width: 450px;
      font-size: 46px;
      line-height: 52px;
      margin-left: -3px
    }

    @include mdScreenMedia {
      max-width: 350px;
      font-size: 43px;
      line-height: 43px;
      top: 11%;
    }

    @include smScreenMedia {
      max-width: 350px;
      font-size: 42px;
    }

    @include xsmScreenMedia {
      font-size: 33px;
      line-height: 38px;
      top: 13%;
      max-width: 310px;
    }
  }
}