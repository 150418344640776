@import "../../../styles/vars";
@import "../../../styles/mixins";


.careerHeader {
  margin-top: 235px;
  margin-bottom: 66px;
  @include flexCol(center, flex-start);

  @include xxlScreenMedia {
    margin-top: 200px;
  }

  @include xlScreenMedia {
    margin-top: 150px;
  }

  @include lgScreenMedia {
    margin-top: 100px;
  }

  @include mdScreenMedia {
    flex-direction: column-reverse;
    align-items: center;
  }

  @include smScreenMedia {
    align-items: flex-start;
    margin-top: 78px;
    margin-bottom: 40px;
  }

  &__picture {
    width: 100%;
    max-width: 904px;

    @include xlScreenMedia {
      max-width: 550px;
    }

    @include lgScreenMedia {
      max-width: 350px;
    }

    @include mdScreenMedia {
      max-width: none;
    }
  }

  &__img {
    width: 100%;
  }

  &__title {
    @include setFont(60px, 500);
    color: $color-white;
    padding-top: 244px;
    padding-left: 122px;

    @include xxlScreenMedia {
      font-size: 45px;
      padding-top: 180px;
    }

    @include xlScreenMedia {
      font-size: 43px;
      padding-top: 120px;
    }

    @include lgScreenMedia {
      font-size: 37px;
      padding-top: 80px;
      padding-left: 85px;
    }

    @include mdScreenMedia {
      padding-top: 0;
      padding-left: 0;
      text-align: center;
      margin-bottom: 35px;
    }

    @include smScreenMedia {
      text-align: left;
      font-size: 33px;
      margin-bottom: 20px;
    }
  }

}
