@import "../../../styles/vars";
@import "../../../styles/mixins";


.homeHeader {
  overflow: hidden;
  height: 978px;

  @media (min-width: 1025px) {
    @include flexCol();
  }

  @include lgScreenMedia {
    height: 960px;
    margin-top: 85px;
  }

  @media (max-width: 720px) {
    height: 585px;
  }

  @include smScreenMedia {
    height: 593px;
    margin-top: 75px;
  }

  @media (max-width: 380px) {
    height: 585px;
  }


  &__container {
    @include flexCol();

    @include lgScreenMedia {
      flex-direction: column;
      gap: 15px;
    }
  }

  &__splineWrapper {
    opacity: 0;
    animation: display .5s linear forwards;
    animation-delay: .5s;
  }

  &__texts {
    width: 100%;
    max-width: 560px;
    z-index: 2;

    @include xlScreenMedia {
      max-width: 416px;
    }

    @include lgScreenMedia {
      text-align: center;
      max-width: 503px;
    }
  }

  &__title {
    margin-bottom: 40px;
    color: $color-white;

    @include xxlScreenMedia {
      margin-bottom: 30px;
    }

    @include lgScreenMedia {
      margin-bottom: 34px;
      text-align: center;
    }

    @include smScreenMedia {
      margin-bottom: 24px;
    }
  }

  &__contentText {
    margin-bottom: 15px;

    @include smScreenMedia {
      margin-bottom: 22px;
    }
  }

  &__btn {
    margin-top: 26px;

    @include smScreenMedia {
      margin-top: 12px;
    }
  }

  &__animation {
    width: 100%;

    iframe {
      width: calc(100% + 770px) !important;
      height: 1100px !important;
      margin-left: -610px;

      @media (max-width: 1800px) {
        margin-left: -500px;
      }

      @media (max-width: 1680px) {
        margin-left: -550px;
      }

      @media (max-width: 1620px) {
        margin-left: -615px;
      }

      @media (max-width: 1550px) {
        margin-left: -570px;
      }

      @media (max-width: 1470px) {
        margin-left: -640px;
      }

      @media (max-width: 1320px) {
        margin-left: -675px;
      }

      @media (max-width: 1200px) {
        margin-left: -620px;
      }

      @media (max-width: 1120px) {
        margin-left: -700px;
      }

      @media (max-width: 1024px) {
        margin-left: -195px;
      }

      @media (max-width: 920px) {
        margin-left: -260px;
      }

      @media (max-width: 860px) {
        margin-left: -320px;
      }

      @media (max-width: 820px) {
        margin-left: -235px;
      }
      @media (max-width: 820px) {
        margin-left: -190px;
      }

      @media (max-width: 770px) {
        margin-left: -235px;
      }

      @media (max-width: 620px) {
        margin-left: -250px;
      }

      @media (max-width: 560px) {
        margin-left: -330px;
      }

      @media (max-width: 450px) {
        margin-left: -328px;
      }
    }
  }
}


@keyframes display {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}
